import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useQuiz } from "../../context/quiz"
import { FadeInUp } from "components/animations/fadeInUp"

const Validation = () => {
    const { submit, resolve } = useQuiz()
    const data = useStaticQuery(graphql`
        query {
            allContentJson {
                edges {
                    node {
                        home {
                            quiz {
                                validation {
                                    title
                                    form {
                                        email
                                        emailError
                                        emailSubscription
                                        civility
                                        civilityMan
                                        civilityWoman
                                        name
                                        nameError
                                        firstname
                                        firstnameError
                                        submit
                                        mandatoryLabel
                                        sending
                                        sent
                                    }
                                    policy
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const content = data.allContentJson.edges[0].node.home.quiz.validation

    const defaultState = {
        formData: {
            civility: content.form.civilityMan,
            email: '',
            name: '',
            firstname: '',
            emailSubscription: false,
        },
        formError: {
            email: false,
            name: false,
            firstname: false,
        },
        formEvent: {
            sending: false,
            sent: false
        }
    }

    const [state, setState] = useState(defaultState)

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setState({
            formData: {
                ...state.formData,
                [name]: value
            },
            formError: {
                ...state.formError,
                [name]: false
            },
            formEvent: {
                ...state.formEvent
            }
        })
    }

    const formValidation = () => {
        let formError = { ...defaultState.formError }
        let inputValidation = Object.keys(state.formData).map(itemName => {
            switch (itemName) {
                case 'email':
                    const emailError = (state.formData.email.match(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null)
                    if (emailError) {
                        formError.email = emailError
                    }
                    return !emailError

                case 'name':
                    const nameError = state.formData.emailSubscription ? !(state.formData.name.length > 0) : false
                    if (nameError) {
                        formError.name = nameError
                    }
                    return !nameError

                case 'firstname':
                    const firstnameError = state.formData.emailSubscription ? !(state.formData.firstname.length > 0) : false
                    if (firstnameError) {
                        formError.firstname = firstnameError
                    }
                    return !firstnameError

                default:
                    return true
            }
        })
        // Inform the state
        setState({
            formData: {
                ...state.formData,
            },
            formError: {
                ...formError
            },
            formEvent: {
                ...state.formEvent
            }
        })
        // Return a reduced value
        return inputValidation.reduce((acc, next) => acc && next)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        const formReady = formValidation()
        const { email, emailSubscription, civility, name, firstname } = state.formData

        if (formReady) {
            setState({
                formData: {
                    ...state.formData,
                },
                formError: {
                    ...state.formError
                },
                formEvent: {
                    ...state.formEvent,
                    sending: true
                }
            })

            // Add contact on sendinblue, via lambda function.
            const winner = resolve()
            // Record result asynchronously.
            fetch("/.netlify/functions/api?submitQuiz", {
                method: "POST",
                body: JSON.stringify({
                    email, emailSubscription, civility, name, firstname,
                    profileAttribute: winner.profile.sendinblue_attribute
                })
            }).catch(() => {})
            submit()
        }
    }

    return (
        <div className="u-pd-hz-l">
            <FadeInUp yOffset={50} delay={100} className="u-mg-bottom-l">
                <h5
                    className="c-h5 u-center u-mg-hz-auto"
                    style={{ maxWidth: "370px" }}
                    dangerouslySetInnerHTML={{__html: content.title}}
                ></h5>
            </FadeInUp>

            <FadeInUp yOffset={50} delay={100} className="">

                {/* email */}
                <div className="c-form-group u-mg-bottom-m">
                    {state.formError.email &&
                        <FadeInUp yOffset={10} delay={100}>
                            <p className="u-danger">{content.form.emailError}</p>
                        </FadeInUp>
                    }
                    <input
                        className={"c-input u-bold " + (state.formError.email ? "has-error" : "")}
                        name="email"
                        type="email"
                        value={state.formData.email} placeholder={content.form.email}
                        onChange={handleInputChange}
                        required=""
                    />
                </div>

                {/* email subscription */}
                <div className="u-flex u-flex-start-vt c-form-group u-mg-bottom-m">
                    <input
                        className="c-checkbox u-mg-right-s"
                        id="emailSubscription"
                        name="emailSubscription"
                        type="checkbox"
                        checked={state.formData.emailSubscription}
                        onChange={handleInputChange}
                    />
                    <label className="u-fs-xs" htmlFor="emailSubscription">
                        {content.form.emailSubscription}
                    </label>
                </div>

                {state.formData.emailSubscription &&
                    <div className="u-pd-vt-m">
                        {/* civility */}
                        <div className="c-form-group u-mg-bottom-m">
                            <label className="u-block u-mg-bottom-s">{content.form.civility}</label>
                            <div className="u-flex">
                                <label htmlFor="genderMan" className={"c-radio c-btn u-width-full u-mg-right-xs " + ((state.formData.civility === content.form.civilityMan) ? "c-btn--secondary is-checked" : "c-btn--secondary-invert")}>
                                    <input type="radio" value={content.form.civilityMan} name="civility" id="genderMan" onChange={handleInputChange} />
                                    {content.form.civilityMan}
                                </label>
                                <label htmlFor="genderWoman" className={"c-radio c-btn u-width-full u-mg-left-xs " + ((state.formData.civility === content.form.civilityWoman) ? "c-btn--secondary is-checked" : "c-btn--secondary-invert")}>
                                    <input type="radio" value={content.form.civilityWoman} name="civility" id="genderWoman"onChange={handleInputChange}  />
                                    {content.form.civilityWoman}
                                </label>
                            </div>
                        </div>

                        {/* name */}
                        <div className="c-form-group u-mg-bottom-m">
                            {state.formError.name &&
                                <FadeInUp yOffset={10} delay={100}>
                                    <p className="u-danger">{content.form.nameError}</p>
                                </FadeInUp>
                            }
                            <input
                                className={"c-input u-bold " + (state.formError.name ? "has-error" : "")}
                                name="name"
                                type="name"
                                value={state.formData.name} placeholder={content.form.name}
                                onChange={handleInputChange}
                                required={state.formData.emailSubscription}
                            />
                        </div>

                        {/* firstname */}
                        <div className="c-form-group u-mg-bottom-m">
                            {state.formError.firstname &&
                                <FadeInUp yOffset={10} delay={100}>
                                    <p className="u-danger">{content.form.firstnameError}</p>
                                </FadeInUp>
                            }
                            <input
                                className={"c-input u-bold " + (state.formError.firstname ? "has-error" : "")}
                                name="firstname"
                                type="firstname"
                                value={state.formData.firstname} placeholder={content.form.firstname}
                                onChange={handleInputChange}
                                required={state.formData.emailSubscription}
                            />
                        </div>
                    </div>
                }

                {!state.formEvent.sending && !state.formEvent.sent &&
                    <button className="c-btn c-btn--secondary u-width-full" onClick={handleSubmit}>
                        <span className={"icon-FLECHE_CTA u-primary u-mg-right-s"}></span>
                        <span className="u-font-adjustment">{content.form.submit}</span>
                    </button>
                }

                {state.formEvent.sending && !state.formEvent.sent &&
                    <FadeInUp yOffset={50} delay={300} className="u-mg-top-l">
                        <p className="u-white">{content.form.sending}</p>
                    </FadeInUp>
                }
                {!state.formEvent.sending && state.formEvent.sent &&
                    <FadeInUp yOffset={50} delay={300} className="u-mg-top-l">
                        <p className="u-white">{content.form.sent}</p>
                    </FadeInUp>
                }

                <div className="u-fs-xs u-italic u-mg-top-m u-mg-bottom-l">{content.form.mandatoryLabel}</div>

                {content.policy.map((item, index) => (
                    <p key={"form-validation-desc-" + index}
                       className={"u-fs-xs " + (index === content.policy.length - 1 ? '' : 'u-mg-bottom-m')}
                       dangerouslySetInnerHTML={{__html: item}}>
                    </p>
                ))}

            </FadeInUp>
        </div>
    )
}

export default Validation
import React from "react";

function useItemSize({
	wrapper,
	callback,
	measure,
	slidesToShow
}) {
	const itemSizeMax = (windowWidth) => windowWidth * 50 / 100 // itemSizeMax is 50vw
	const updateItemSize = React.useCallback(
		() => {
			requestAnimationFrame(() => {
				if (wrapper.current) {
					const rect = wrapper.current.getBoundingClientRect();
					const itemSize = Math.round(rect[measure] / slidesToShow)
					callback(Math.round((itemSize < itemSizeMax(rect.width)) ? itemSize : itemSizeMax(rect.width)))
				}
			})
		},
		[slidesToShow, callback]
	);

	React.useLayoutEffect(() => {
		updateItemSize();
		window.addEventListener("resize", updateItemSize);
		return () => window.removeEventListener("resize", updateItemSize);
	}, []);
}

export default useItemSize;

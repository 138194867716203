import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Page from "components/page"
import Layout from "components/layout"
import Quiz from "components/quiz"
import ContentBand1 from "components/blocks/content-band-1"
import ContentBand2 from "components/blocks/content-band-2"
import Barometer from "components/blocks/barometer"
import SEO from "components/seo"
import Media from "components/media"

const HomePage = (props) => {
    const data = useStaticQuery(graphql`
        query {
            allContentJson {
                edges {
                    node {
                        home {
                            headband {
                                suptitle
                                title
                            }
                        }
                    }
                }
            }
            blockHeadband: file(sourceInstanceName: {eq: "blocks"}, name: {eq: "home-headband"}) {
                id
                childBlocksYaml {
                    suptitle
                    title
                    image {
                        childImageSharp {
                            fluid(maxWidth: 570, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                        publicURL
                    }
                }
            }
            blockDonate: file(sourceInstanceName: {eq: "blocks"}, name: {eq: "donate"}) {
                id
                childMarkdownRemark {
                    id
                    frontmatter {
                        title
                        image {
                            childImageSharp {
                                fluid(maxWidth: 600, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                            publicURL
                        }
                        button {
                            text
                            url
                        }
                    }
                    html
                }
            }
            blockDiscover: file(sourceInstanceName: {eq: "blocks"}, name: {eq: "discover"}) {
                id
                childMarkdownRemark {
                    id
                    frontmatter {
                        title
                        video_url
                        button {
                            text
                            url
                        }
                    }
                    html
                }
            }
        }
    `)

    const content = data.allContentJson.edges[0].node.home
    const blockHeadband = data.blockHeadband.childBlocksYaml
    const blockDonate = data.blockDonate.childMarkdownRemark
    const blockDiscover = data.blockDiscover.childMarkdownRemark

    const quizRef = React.useRef(null)
    const scrollToQuiz = () => {
        window.scrollTo(0, quizRef.current.offsetTop);
    }

    return (
        <>
            <SEO />
            <Helmet>
                {/* Handle Netlify Identity requests for the domain. */}
                <script src="https://identity.netlify.com/v1/netlify-identity-widget.js" defer></script>
            </Helmet>
            <Page>
                <Layout {...props} className={"c-content u-overflow-hidden"} onScrollToQuiz={scrollToQuiz}>
                    <div className="c-headband c-headband--home u-pd-top-navbar">
                        <div className="l-container">
                            <div className="c-headband__content u-center u-left@main u-pd-vt-l@main">
                                <div className="l-grid">
                                    <div className="l-col-12 l-col-6@main u-pd-top-xl@main">
                                        <h4 className="c-h4 u-white u-mg-bottom-s">{blockHeadband?.suptitle || content?.headband?.suptitle || ""}</h4>
                                        <h1 className="c-h1 c-title-span u-mg-bottom-l" dangerouslySetInnerHTML={{ __html: blockHeadband?.title || content?.headband?.title || "" }}></h1>
                                        <div id="quiz" ref={quizRef}><Quiz /></div>
                                    </div>

                                    <div className="l-col-12 l-col-6@main u-relative u-hide u-block@main u-zi--1">
                                        <div className="u-overflow-hidden u-br-sc" style={{ height: '730px' }}>
                                            {blockHeadband.image && (
                                                <Media
                                                    url={
                                                        blockHeadband.image?.childImageSharp?.fluid ||
                                                        blockHeadband.image?.publicURL ||
                                                        blockHeadband.image
                                                    }
                                                    width="100%"
                                                    height="100%"
                                                    style={{ objectFit: 'cover' }}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="c-trame-home"></div>
                        </div>
                    </div>

                    <ContentBand1
                        className={"u-pd-vt-l@main"}
                        title={blockDonate.frontmatter.title}
                        desc={blockDonate.html}
                        ctaLabel={blockDonate.frontmatter.button.text}
                        ctaLink={blockDonate.frontmatter.button.url}
                        url={blockDonate.frontmatter.image.childImageSharp.fluid}
                    />

                    <ContentBand2
                        className={"u-pd-vt-l@main"}
                        title={blockDiscover.frontmatter.title}
                        desc={blockDiscover.html}
                        ctaIcon={"icon-FLECHE_CTA"}
                        ctaLabel={blockDiscover.frontmatter.button.text}
                        ctaLink={blockDiscover.frontmatter.button.url}
                        url={blockDiscover.frontmatter.video_url}
                    />

                    <Barometer />

                    <hr className="u-mg-0 u-border-blue-dark" />
                </Layout>
            </Page>
        </>
    )
}

export default HomePage

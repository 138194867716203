import React from 'react'
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useQuiz } from "context/quiz"

export const QuizResult = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentJson {
                edges {
                    node {
                        home {
                            quiz {
                                results {
                                    calculation
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const content = data.allContentJson.edges[0].node.home.quiz.results
    const { profile, reset } = useQuiz()

    React.useEffect(() => {
        if (profile) {
            // Reset quiz.
            reset()
            navigate(`/vous-etes/${profile.slug}`)
        }
    }, [profile])

    return <p>{content.calculation}</p>
}

export default QuizResult
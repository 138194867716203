import React from "react"
import ImgPolyfill from "gatsby-image/withIEPolyfill"
import YoutubeVideo from "components/youtubeVideo"

const Media = ({ url, alt, objectFit, className, ...props }) => {
    let type = typeof url === 'object' && url.hasOwnProperty('src') ?
        "gatsbyImage"
        : typeof url === 'string' && (url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) !== null || url.startsWith('https://placeimg')) ?
            "image"
            : typeof url === 'string' ?
                "video"
                : null

    switch (type) {
        case "gatsbyImage":
            return (
                <ImgPolyfill
                    fluid={url}
                    objectFit={objectFit || 'cover'}
                    objectPosition="50% 50%"
                    fadeIn={false}
                    className={className}
                    loading="eager"
                    alt={alt}
                    {...props}
                />
            )
        case "image":
            return (
                <img
                    src={url}
                    className={className}
                    width="100%"
                    height="100%"
                    style={{ objectFit: (objectFit || 'cover') }}
                    alt={alt}
                    {...props}
                />
            )
        case "video":
            return <YoutubeVideo url={url} className={className} {...props} />
        default :
            return <></>
    }
}
export default Media

import React from "react"
import DefaultLink from "components/defaultLink"
import Media from "components/media"

const ContentBand1 = ({ title, desc, ctaIcon, ctaLabel, ctaLink, url, className }) => {
    return (
        <section className={"c-content-band-1 u-center u-left@main " + (className || '')}>
           <div className="l-container">
                <div className="c-content-band-1__bkg l-grid u-flex-dir-row-reverse@main u-pd-vt-l u-pd-vt-xl@main">
                    <div className="l-col-12 l-col-6@main u-pd-hz-l u-pd-hz-xxl@main u-pd-vt-l">
                        <h2 className="c-h2 c-content-band-1__title u-mg-bottom-m">{title}</h2>
                        {url && (
                            <Media url={url} alt={title} className={"u-hide@main u-mg-bottom-m"} style={{ height: "auto", maxHeight: "500px" }} />
                        )}
                        <div className="u-white u-mg-bottom-xs" dangerouslySetInnerHTML={{__html: desc}}></div>
                        <div className="u-flex u-flex-center-hz u-flex-start@main u-mg-top-l">
                            <DefaultLink link={ctaLink} className={"c-btn c-btn--primary u-pd-hz-l"}>
                                {ctaIcon && <span className={ctaIcon + " u-secondary u-mg-right-s"}></span>}
                                <span className="u-font-adjustment">{ctaLabel}</span>
                            </DefaultLink>
                        </div>
                    </div>
                    {url && (
                        <div className="l-col-6 u-hide u-block@main u-relative">
                            <Media url={url} alt={title} className={"u-absolute@main u-top-0 u-left-0 u-height-full u-width-full"} />
                        </div>
                    )}
                </div>
           </div>
        </section>
    )
}
export default ContentBand1

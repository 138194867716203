import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { useQuiz } from "context/quiz"
import ProgressIndicator from "./progressIndicator"
import Validation from "./validation"
import { parseMarkdownAsHTML } from "utils/markdown"
import { shuffleArray } from "utils/array"

export const QuizForm = ({ className, style }) => {
    const data = useStaticQuery(graphql`query {
        allContentJson {
            edges {
                node {
                    id
                    home {
                        quiz {
                            mandatory
                        }
                    }
                }
            }
        }
    }`)
    const { quiz } = data.allContentJson.edges[0].node.home
    const { title, currentQuestion, answers, questions, answer, submit, require_email, navigateTo } = useQuiz()

    const [sortedAnswers, setSortedAnswers] = React.useState([])
    React.useEffect(() => {
        setSortedAnswers(
            currentQuestion < questions.length ?
                shuffleArray(questions[currentQuestion].answers)
                : []
        )
    }, [currentQuestion])

    // If no validation step, and quiz is over, validate.
    React.useEffect(() => {
        if (currentQuestion >= questions.length && !require_email)
            submit()
    }, [currentQuestion, questions.length, require_email])

    const quizRef = React.useRef()
    const scrollToQuiz = () => {
        if ("scrollTo" in window && typeof quizRef?.current?.offsetTop !== "undefined")
            window.scrollTo(0, quizRef.current.offsetTop);
    }

    const handleAnswer = event => {
        answer(event.currentTarget.value)
        navigateTo(currentQuestion + 1)
        scrollToQuiz()
    }

    return (
        <form ref={quizRef} className={"c-quiz u-pd-l u-pd-xl@main " + (className ? className : '')} style={style}>
            {currentQuestion < questions.length && (
                <>
                    <h2 className="u-fs-m u-font-heading u-center u-uppercase u-mg-bottom-s">
                        { title + ' ' + (currentQuestion + 1) + '/' + questions.length }
                    </h2>

                    <ProgressIndicator
                        className={'u-mg-bottom-m'}
                        currentQuestion={currentQuestion}
                        quizLength={questions.length}
                    />

                    <div className="u-mg-bottom-l">
                        <div className="u-bold u-fs-s u-mg-bottom-l" htmlFor="name">
                            {questions[currentQuestion].question}&nbsp;:&nbsp;
                            {questions[currentQuestion].mandatory &&
                                <span className="u-light u-italic">{quiz.mandatory}</span>
                            }
                        </div>

                        {sortedAnswers.map(({ hash, answer }, index) => (
                            <label key={"quiz-answer-" + currentQuestion + '-' + index} className="u-block u-mg-bottom-m">
                                <input type="radio" name="answer" value={hash} onChange={handleAnswer}/>
                                <div
                                    className={"c-quiz__answer u-pd-s " + (answers[currentQuestion].hash === hash  ? 'is-checked' : '')}
                                    dangerouslySetInnerHTML={{__html: parseMarkdownAsHTML(answer)}}
                                ></div>
                            </label>
                        ))}
                    </div>
                </>
            )}

            {currentQuestion >= questions.length && require_email && <Validation />}
        </form>
    )
}

export default QuizForm

import React from 'react'
import { useQuiz } from 'context/quiz'
import QuizForm from './form'
import QuizResult from './result'

export const Quiz = () => {
    const { profile } = useQuiz()
    return !profile ? < QuizForm /> : <QuizResult />
}

export default Quiz
import React from "react";
import useTouch from "./useTouch";
import useSlides from "./useSlides";
import useNavigation from "./useNavigation";
import useItemSize from "./useItemSize";
import { useMedia } from 'react-use'

const Carousel = ({
	children,
	slidesToShow = 3,
	firstItemIndex = 1,
	infinite = true,
	transitionDuration = 300,
	centerCurrentSlide = true,
	render = ({ slides }) => slides,
	currentItemChange,
	className
}, ref) => {

	const isMobile = useMedia('(max-width: 991px)')

	// Get the cloned children / slides from Slides hook
	const { slides, slideCount, preSlidesCount } = useSlides(children, {
		infinite,
		slidesToShow
	});

	// Get corresponding methods from Navigation hook
	const navigation = useNavigation({
		infinite,
		slidesToShow,
		slideCount,
		firstItemIndex
	});

	const { previous, next, goToStep, currentIndex } = navigation;

	// Pass currentIndex data to parent on each render
	React.useEffect(() => {
		currentItemChange(currentIndex)
	}, [currentIndex,currentItemChange]);

	// Open some handle to parent
	React.useImperativeHandle(
		ref,
		() => ({
			previous,
			next,
			goToStep
		}),
		[previous, next, goToStep]
	);

	const wrapper = React.useRef(null);
	const [itemSize, setItemSize] = React.useState(0);
	const [disableTransition, setDisableTransition] = React.useState(false);

	// Calculate the size for each item then callback
	useItemSize({
		wrapper,
		callback: size => {
			if (itemSize !== size) {
				setDisableTransition(true);
				setItemSize(size);
			}
		},
		slidesToShow,
		measure: "width"
	});

	React.useEffect(() => {
		if (disableTransition) {
			if (currentIndex < 0 || currentIndex >= slideCount) {
				requestAnimationFrame(() =>
					navigation.goToStep(index => {
						if (index < 0) {
							if (infinite) return slideCount + index;
							return 0;
						} else {
							return index - slideCount;
						}
					})
				);
			} else {
				requestAnimationFrame(() => setDisableTransition(false));
			}
		} else if (currentIndex >= slideCount || currentIndex < 0) {
			setTimeout(() => setDisableTransition(true), transitionDuration);
		}
	}, [disableTransition, currentIndex, slideCount, transitionDuration]);

	// Get methods from Touch hook
	const {
		onTouchStart,
		onTouchMove,
		onTouchEnd,
		isTouching,
		touchOffset,
		onClickCapture
	} = useTouch(
		React.useCallback(
			offset => {
				// Make it a bit easier to switch slides by adding 30% of item size to the offset
				const adjustedOffset =
					offset > 0 ? offset + itemSize * 0.3 : offset - itemSize * 0.3;
				const slidesMoved = Math.round(adjustedOffset / itemSize);
				if (infinite) {
					navigation.goToStep(index => index - slidesMoved);
				} else {
					if (slidesMoved === 0) return;
					navigation.goToStep(prevIndex => {
						if (prevIndex - slidesMoved > slideCount - slidesToShow) {
							return slideCount - slidesToShow;
						}
						if (prevIndex - slidesMoved < 0) {
							return 0;
						}
						return prevIndex - slidesMoved;
					});
				}
			},
			[itemSize, slideCount, slidesToShow, navigation.goToStep]
		)
	);

	const calculateCenteringOffset = () => {
		if (centerCurrentSlide) {
			// Centering with a single item is tricky since the item is not
			// 100% of the container and we need actually to show items around
			if (slidesToShow === 1) {
				// On mobile, by design, the active element is bigger than the others,
				// it can break calculation
				if (isMobile) {
					// On infinite mode, items are cloned and there is hence multiple
					// active / bigger items, it can break calculation
					if (infinite) {
						return itemSize / 4
					}
					else {
						// we translate the item to one item on the right
						// then remove half of the bigger item to the left
						return itemSize - mobileMainItemSize / 2
					}
				}
				else {
					// we translate the item to one item on the right
					// then remove half of the item to the left
					return itemSize / 2
				}
			}
			else {
				return (slidesToShow / 2 - 0.5) * itemSize
			}
		}
		else {
			return 0
		}
	}

	const mobileMainItemSize = (itemSize * 60 / 50)
	const centeringOffset = calculateCenteringOffset()
	const offset = touchOffset - (currentIndex + preSlidesCount) * itemSize + centeringOffset;

	const transition = disableTransition || isTouching ? 0 : transitionDuration;

	const initialOffset =
		-(currentIndex + preSlidesCount) * (100 / slidesToShow) +
		(centerCurrentSlide ? (slidesToShow / 2 - 0.5) * (100 / slidesToShow) : 0);

	return render({
		...navigation,
		slides: (
			<div
				className={className}
				style={{
					width: "100%",
					overflow: "hidden"
				}}
				ref={wrapper}
			>
				<div
					onTouchStart={onTouchStart}
					onTouchMove={onTouchMove}
					onTouchEnd={onTouchEnd}
					onMouseDown={onTouchStart}
					onMouseMove={onTouchMove}
					onMouseUp={onTouchEnd}
					onMouseLeave={onTouchEnd}
					onClickCapture={onClickCapture}
					style={{
						display: "flex",
						flexDirection: "row",
						transform: `translateX(${
							itemSize ? `${offset}px` : `${initialOffset}%`
						})`,
						transition: `transform ${transition}ms ease`,
						touchAction: "pan-y pinch-zoom"
					}}
				>
					{slides}
				</div>
			</div>
		),
		slidesToShow,
		infinite,
		transitionDuration,
		centerCurrentSlide
	});
};

export default React.forwardRef(Carousel);

import React from "react"

const ProgressIndicator = ({ className, currentQuestion, quizLength }) => {
    let i = 0, progress = []
    for (i; i < quizLength; ++i)
        progress.push(<span key={i} className={i > currentQuestion ? 'is-not-done' : ''}></span>)
    return <div className={"c-quiz-progress " + (className || '')}>{progress}</div>
}

export default ProgressIndicator

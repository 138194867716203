import React from "react"
import DefaultLink from "components/defaultLink"
import YoutubeVideo from "components/youtubeVideo"

const ContentBand2 = ({ url, title, desc, ctaIcon, ctaLabel, ctaLink, className }) => {
    return (
        <section className={"c-content-band-2 u-center u-left@main " + (className || '')}>
           <div className="l-container">
                <div className="c-content-band-2__bkg l-grid u-pd-vt-xxl">
                    <div className="l-col-12 l-col-6@main u-pd-hz-l u-pd-hz-xxl@main">
                        <h2 className="c-h2 u-secondary u-mg-bottom-m">{title}</h2>
                        {url && (
                            <YoutubeVideo url={url}
                                className={"c-video--sized u-hide@main u-mg-bottom-m"}
                                playerProps={{
                                    playing: true,
                                    controls: true,
                                    light: true,
                                    config: {
                                        youtube: {
                                            playerVars: {
                                                color: "#EA6548",
                                                autoplay: 1,
                                                controls: 1,
                                                fs: 0,
                                                modestbranding: 1,
                                            },
                                        }
                                    }
                                }}
                            />
                        )}
                        <div className="u-white u-mg-bottom-xs" dangerouslySetInnerHTML={{__html: desc}}></div>
                        <div className="u-flex u-flex-center-hz u-flex-start@main u-mg-top-l">
                            <DefaultLink link={ctaLink} className={"c-btn c-btn--secondary u-primary u-pd-hz-l"}>
                                {ctaIcon && <span className={ctaIcon + " u-white u-mg-right-s"}></span>}
                                <span className="u-font-adjustment">{ctaLabel}</span>
                            </DefaultLink>
                        </div>
                    </div>
                    <div className="l-col-6 u-hide u-block@main u-relative">
                        {url &&(
                            <YoutubeVideo url={url}
                                className={"u-absolute@main u-top-0 u-left-0 u-height-full u-width-full"}
                                playerProps={{
                                    playing: true,
                                    controls: true,
                                    light: true,
                                    config: {
                                        youtube: {
                                            playerVars: {
                                                color: "#EA6548",
                                                autoplay: 1,
                                                controls: 1,
                                                fs: 0,
                                                modestbranding: 1,
                                            },
                                        }
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
           </div>
        </section>
    )
}
export default ContentBand2

import React from "react";

function useSlides(children, {
	infinite,
	slidesToShow
}) {
	return React.useMemo(() => {
		const originalSlides = React.Children.toArray(children);
		const slideCount = originalSlides.length;

		const preSlides = infinite
			? originalSlides.slice(slideCount - (slidesToShow + 2))
			: [];
		const postSlides = infinite
			? originalSlides.slice(0, slidesToShow + 2)
			: [];

		const slides = [...preSlides, ...originalSlides, ...postSlides].map((child, index) =>
			React.cloneElement(child, {
				style: {
					...child.props.style,
					flex: `0 0 auto`,
					width: `${100 / slidesToShow}%`
				},
				key: index
			})
		);

		return {
			slides,
			slideCount,
			preSlidesCount: preSlides.length
		};

	}, [children, infinite, slidesToShow]);
}

export default useSlides;

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from "utils/framer-motion"
import Carousel from "components/carousel/carousel"
import Loader from "components/loader"

const BarometerCard = ({ number, icon, label, ...props }) => (
    <motion.div className={"c-barometer__card u-pd-l"} {...props}>
        <div className={icon + " u-primary u-fs-xl"}></div>
        {
            number ?
                <div className="u-secondary u-bold u-fs-h4 u-mg-vt-xs">{number}</div>
                : <Loader label="Chargement" className="u-mg-vt-m"/>
        }
        <p className="u-primary">{label}</p>
    </motion.div>
)

const Barometer = ({ className }) => {
    const { blockBarometer } = useStaticQuery(graphql`query{
        blockBarometer: file(sourceInstanceName: {eq: "blocks"}, name: {eq: "barometer"}) {
            id
            childBlocksYaml {
                id
                title
                items {
                    title
                    name
                    icon
                    override
                }
            }
        }
    }`)
    const { title, items } = blockBarometer.childBlocksYaml
    const [metrics, setMetrics] = React.useState(null)
    const [currentItem, setCurrentItem] = React.useState(0)
    const gap = 20
    const infiniteCarousel = false

    React.useEffect(() => {
        // Only fetch barometer if data is not overriden.
        if (items.find(i => !i.override)) {
            try {
                fetch("/.netlify/functions/api?barometer")
                .then(res => res.json())
                .then(data => data && setMetrics(data))
            }
            catch (error) {
                console.error(error.message)
            }
        }
    }, [items])

    return (
        <section className={"u-bg-primary u-bg-white@main u-pd-vt-xl"}>
            <div className="l-container">
                <h2 className="c-h2 c-barometer-title u-center u-white u-primary@main">{title}</h2>
            </div>

            <section className={"c-barometer u-pd-vt-l u-pd-hz-l" + (className || '')}>
                <Carousel
                    className="c-carousel u-mobile-only"
                    slidesToShow={1}
                    firstItemIndex={currentItem}
                    infinite={infiniteCarousel}
                    currentItemChange={setCurrentItem}
                >
                    {items.map((item, index) => (
                        <div key={index} className={"c-carousel__item " + (currentItem === index ? 'is-active' : '')} style={{ padding: `0 ${gap / 2}px`}}>
                            <BarometerCard
                                number={item.override || (metrics && metrics[item.name])}
                                icon={item.icon}
                                label={item.title}
                                metrics={metrics}
                            />
                        </div>
                    ))}
                </Carousel>

                <div className="l-container-lg u-desktop-only">
                    <div className="l-grid">
                        {items.map((item, index) => (
                            <div className="l-col-4" key={'item-' + index}>
                                <BarometerCard
                                    number={item.override || (metrics && metrics[item.name])}
                                    icon={item.icon}
                                    label={item.title}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </section>
    )
}
export default Barometer

import React from "react";

const Loader = ({ label, className }) => (
    <div className={"c-loader " + (className || "")} aria-label={label || 'Loading'}>
        <div></div>
        <div></div>
        <div></div>
    </div>
)

export default Loader;



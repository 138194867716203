import React from "react";
import { isIosDevice } from "./isIosDevice";

function useTouch(callback) {
	const isSwiping = React.useRef(false);
	const touchStartX = React.useRef(null);
	const touchStartY = React.useRef(null);
	const [touchOffset, setTouchOffset] = React.useState(0);

	const reset = () => {
		setTimeout(() => setTouchOffset(0), 0);
		touchStartX.current = null;
		touchStartY.current = null;
		isSwiping.current = false;
	};

	React.useEffect(() => {
		if (isIosDevice) {
			const preventDefault = (event) => {
				if (event.touches.length === 1 && isSwiping.current) {
					if (event.cancelable) {
						event.preventDefault();
					} else {
						reset();
					}
				}
			};

			document.addEventListener("touchmove", preventDefault, {
				passive: false
			});

			return () => {
				document.removeEventListener("touchmove", preventDefault);
			};
		}
	}, []);

	const onTouchStart = React.useCallback(
		(event) => {
			if (
				event.changedTouches &&
				event.changedTouches.length > 1
			) {
				// Multiple touch points indicates an attempt to pinch-to-zoom
				return null;
			}
				const x = event.changedTouches
					? event.changedTouches[0].clientX
					: event.clientX;
				const y = event.changedTouches
					? event.changedTouches[0].clientY
					: event.clientY;

				touchStartX.current = x;
				touchStartY.current = y;
			},
		[]
	);

	const onTouchMove = React.useCallback(
		(event) => {
			if (touchStartX.current !== null) {
				if (
					event.changedTouches &&
					event.changedTouches.length > 1
				) {
					reset();
				}

				const x = event.changedTouches
					? event.changedTouches[0].clientX
					: event.clientX;
				const y = event.changedTouches
					? event.changedTouches[0].clientY
					: event.clientY;

				const diffX = x - touchStartX.current;
				const diffY = y - touchStartY.current;
				setTouchOffset(diffX);

				if (isIosDevice && isSwiping.current) {
					event.stopPropagation();
				}

				if (!isSwiping.current) {
					if (Math.abs(diffY) > 10 * window.devicePixelRatio) {
						reset();
					} else if (Math.abs(diffX) > 5 * window.devicePixelRatio) {
						isSwiping.current = true;
					}
				}
			}
		},
		[touchStartX]
	);

	const onTouchEnd = React.useCallback(() => {
		callback(touchOffset);
		reset();
	}, [touchOffset, callback]);

	const onClickCapture = React.useCallback(
		event => {
			// Prevent click events when we're swiping
			if (Math.abs(touchOffset) > 5) {
				event.preventDefault();
				event.stopPropagation();
			}
		},
		[touchOffset]
	);

	return {
		isTouching: touchStartX.current !== null,
		touchOffset,
		onTouchStart,
		onTouchMove,
		onTouchEnd,
		onClickCapture
	};
}

export default useTouch;
